<template>
    <Dialog v-model:open="isOpen">
        <DialogContent v-if="part" class="md:max-w-4xl">
            <DialogTitle>
                {{ part.PartNumber }} <small>{{ part.Manufacturer.Name }}</small>
            </DialogTitle>
            <div class="text-center">
                <h4 class="text-lg">{{ $t("Charts.Title") }}</h4>

                <PartChart
                    :part="part"
                    :part-key="partKey"
                    :preview-height="props.previewHeight"
                    :load-chart-data="props.loadChartData"
                    :initial-chart-data="chartData" />
            </div>
            <DialogFooter>
                <DialogClose as-child>
                    <button type="button" class="btn btn-default">
                        {{ $t("Global.Close") }}
                    </button>
                </DialogClose>
            </DialogFooter>
        </DialogContent>
    </Dialog>
</template>
<script setup lang="ts">
const { gtag } = useGtag();

type Props = {
    previewHeight?: string | null;
    previewWidth?: string | null;
    loadChartData?: boolean;
    previewIdPrefix?: string;
};

const props = withDefaults(defineProps<Props>(), {
    initialChartData: null,
    previewHeight: null,
    previewWidth: null,
    loadChartData: false,
    previewIdPrefix: "",
});

defineExpose({ open, openPartDetail });

const chartData = ref<PartChartData | null>();
const isOpen = ref(false);
const part = ref<CommonPart | null>(null);
const partKey = ref("");

function openPartDetail(partDetail: PartDetailResult, initialData?: PartChartData | null) {
    const commonPart: CommonPart = {
        ...partDetail,
        Manufacturer: { Id: partDetail.ManufacturerId, Name: partDetail.ManufacturerName },
    };
    open(commonPart, partDetail.Key, initialData);
}

function open(commonPart: CommonPart, key: string, initialData?: PartChartData | null) {
    part.value = commonPart;
    partKey.value = key;
    if (initialData) chartData.value = initialData;

    gtag("event", "chart_open", {
        manufacturer_name: commonPart.Manufacturer.Name,
        part_number: commonPart.PartNumber,
    });

    isOpen.value = true;
}
</script>
