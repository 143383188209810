<template>
    <div class="text-center part-chart">
        <template v-if="props.separateCharts">
            <div class="flex flex-col lg:flex-row">
                <div class="w-full h-[250px] lg:w-1/2 lg:h-[350px]">
                    <canvas :id="chartIdStock" ref="canvasStock" :aria-label="chartLabelStock" role="img" />
                </div>
                <div class="w-full h-[250px] lg:w-1/2 lg:h-[350px]">
                    <canvas :id="chartIdPrice" ref="canvasPrice" :aria-label="chartLabelPrice" role="img" />
                    <div class="w-4/5 mx-auto mt-4">
                        <label
                            class="font-bold max-w-full mb-1.5 inline-block"
                            :for="qtyInputId"
                            v-html="enterQuantityText" />
                        <div class="input-group input-group-with-button max-w-[300px] mx-auto">
                            <input
                                :id="qtyInputId"
                                v-model.number="newQuantity"
                                type="number"
                                class="form-control"
                                :placeholder="$t('Charts.EnterQuantityPlaceHolder')" />
                            <button
                                class="input-group-after btn btn-bg-primary whitespace-nowrap"
                                type="button"
                                @click="get()">
                                {{ $t("Charts.GenerateChart") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="w-full h-[250px] sm:h-[300px] md:h-[350px]">
                <canvas :id="chartIdPriceStock" ref="canvasPriceStock" :aria-label="chartLabelPriceStock" role="img" />
            </div>
            <div class="form-group part-chart-form">
                <label :for="qtyInputId" class="font-bold mb-2 text-base block" v-html="enterQuantityText" />
                <div class="input-group input-group-with-button max-w-[300px] mx-auto">
                    <input
                        :id="qtyInputId"
                        v-model.number="newQuantity"
                        type="number"
                        class="text-sm"
                        :placeholder="$t('Charts.EnterQuantityPlaceHolder')" />
                    <button class="input-group-after btn btn-bg-primary whitespace-nowrap" type="button" @click="get()">
                        {{ $t("Charts.GenerateChart") }}
                    </button>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import "chartjs-adapter-moment";
import {
    Chart,
    LineController,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Legend,
    Tooltip,
} from "chart.js";
import { nextTick } from "vue";
Chart.register(LineController, TimeScale, LinearScale, PointElement, LineElement, Filler, Legend, Tooltip);

const { t } = useI18n();
const { gtag } = useGtag();
const api = useApi();
const globalConfig = useStateGlobalConfig();

type Props = {
    part: CommonPart;
    partKey: string;
    initialChartData?: PartChartData | null;
    loadChartData?: boolean;
    separateCharts?: boolean;
    isBot?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
    loadChartData: false,
    initialChartData: null,
});

const chartData = ref<PartChartData | null>(props.initialChartData);
const quantity = ref<number | null>(null);
const newQuantity = ref<number | null>(null);
const canvasPrice = useTemplateRef("canvasPrice");
const canvasStock = useTemplateRef("canvasStock");
const canvasPriceStock = useTemplateRef("canvasPriceStock");

let chart1: Chart<"line", object, unknown> | null = null;
let chart2: Chart<"line", object, unknown> | null = null;

const chartIdStock = computed(() => {
    return "stock-chart-" + props.partKey;
});

const chartIdPrice = computed(() => {
    return "price-chart-" + props.partKey;
});

const chartIdPriceStock = computed(() => {
    return "price-stock-chart-" + props.partKey;
});

const chartLabelStock = computed(() => {
    return t("Charts.StockChartFor").replace("{0}", props.part.PartNumber);
});

const chartLabelPrice = computed(() => {
    return t("Charts.PriceChartFor").replace("{0}", props.part.PartNumber);
});

const chartLabelPriceStock = computed(() => {
    return t("Charts.PriceStockChartFor").replace("{0}", props.part.PartNumber);
});

const qtyInputId = computed(() => {
    return "qty-input-" + props.partKey;
});

const ready = computed(() => chartData.value !== null);

const enterQuantityText = computed(() => {
    return t("Charts.EnterQuantity", [quantity.value ? quantity.value.toLocaleString() : 0]);
});

watch(chartData, () => {
    generate();
});

watch(
    () => props.initialChartData,
    (newVal) => {
        // if the prop has changed, update the local data, but not if
        // the caller wants this component to be responsible for the chart data
        if (!props.loadChartData) {
            chartData.value = newVal;
        }
    }
);

onMounted(() => {
    // Sometimes chartData may be missing if we don't want to show it,
    // in other cases we want to load the data via ajax for performance
    if (props.loadChartData) {
        load(quantity.value);
    }

    // use dummy data (for bots)
    if (props.isBot) {
        // Need vue to finish rendering before we set this
        nextTick(() => {
            chartData.value = {
                Stock: [82, 87, 76, 105, 110, 98, 75, 65, 79, 88],
                Price: [0.8, 0.86, 0.86, 0.9, 0.9, 0.85, 0.77, 0.7, 0.79, 0.83],
                Dates: [...Array(10)].map((_, i) => {
                    const d = new Date();
                    d.setDate(d.getDate() - i);
                    return d.toISOString().split("T")[0];
                }),
                Quantity: 10,
            };
        });
    }

    if (ready.value) {
        generate();
    }
});

function makeChart(canvas: HTMLCanvasElement, charts: ChartDefinition[]) {
    const datasets = charts.map(function (chart) {
        return {
            yAxisID: chart.id,
            label: chart.label,
            data: chart.data,
            borderColor: "rgb(" + chart.rgb + ")",
            borderWidth: 1,
            backgroundColor: "rgba(" + chart.rgb + ", 0.3)",
            fill: true,
        };
    });
    const scales: Record<string, object> = {};
    charts.forEach(function (chart) {
        scales[chart.id] = {
            stacked: true,
            beginAtZero: true,
            position: chart.position || "left",
            title: {
                text: chart.label,
                display: true,
            },
            ticks: {
                display: false,
            },
            grid: {
                display: false,
                drawTicks: false,
            },
        };
    });
    return new Chart(canvas, {
        type: "line",
        data: {
            labels: chartData.value?.Dates,
            datasets: datasets || [],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                mode: "index",
                intersect: false,
            },
            elements: {
                point: {
                    radius: 2,
                },
            },
            scales: {
                ...(scales || {}),
                x: {
                    type: "time",
                    time: {
                        tooltipFormat: "MMM DD, yyyy",
                    },
                },
            },
            plugins: {
                legend: {
                    onClick: (e, legendItem, legend) => {
                        gtag("event", "chart_toggle_legend");
                        const index = legendItem.datasetIndex;
                        const ci = legend.chart;
                        if (index && ci.isDatasetVisible(index)) {
                            ci.hide(index);
                            legendItem.hidden = true;
                        } else if (index) {
                            ci.show(index);
                            legendItem.hidden = false;
                        }
                    },
                    position: "bottom",
                },
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            let label = context.dataset.label || "";

                            if (label) {
                                label += ": ";
                            }

                            if (context.parsed.y !== null && context.dataset.label == t("Charts.Price")) {
                                label += new Intl.NumberFormat(getBrowserLocale(), {
                                    style: "currency",
                                    currency: globalConfig.value.CurrencyCode,
                                    maximumFractionDigits: 4,
                                }).format(context.parsed.y);
                            }

                            if (context.parsed.y !== null && context.dataset.label == t("Global.Stock")) {
                                label += context.parsed.y.toLocaleString();
                            }

                            return label;
                        },
                    },
                },
            },
        },
    });
}

function generate() {
    // We shouldn't do anything if the chartData isn't loaded yet
    if (chartData.value == null) return;

    // set the default quantity determined by the server
    quantity.value = chartData.value.Quantity;

    if (!newQuantity.value) {
        newQuantity.value = quantity.value;
    }

    if (chart1) {
        chart1.destroy();
    }

    if (chart2) {
        chart2.destroy();
    }

    if (props.separateCharts) {
        if (canvasStock.value == null || canvasPrice.value == null) return;
        chart1 = makeChart(canvasStock.value, [
            {
                id: "yStock",
                label: t("Global.Stock"),
                rgb: "23, 172, 235",
                data: chartData.value.Stock,
            },
        ]);
        chart2 = makeChart(canvasPrice.value, [
            {
                id: "yPrice",
                label: t("Charts.Price"),
                rgb: "46, 125, 50",
                data: chartData.value.Price,
            },
        ]);
    } else {
        if (canvasPriceStock.value == null) return;
        chart1 = makeChart(canvasPriceStock.value, [
            {
                id: "yStock",
                label: t("Global.Stock"),
                rgb: "23, 172, 235",
                data: chartData.value.Stock,
            },
            {
                id: "yPrice",
                label: t("Charts.Price"),
                rgb: "134, 153, 164",
                data: chartData.value.Price,
                position: "right",
            },
        ]);
    }
}

function get() {
    if (!newQuantity.value) {
        return;
    }

    gtag("event", "chart_update_quantity");

    load(newQuantity.value);
}

async function load(quantity: number | null) {
    // Don't make calls to the part-chart endpoint when we don't have sufficient part information
    if (!props.part.PartNumber || !props.part.Manufacturer.Id) {
        return;
    }

    const body = {
        Parts: [{ PartNumber: props.part.PartNumber, ManufacturerId: props.part.Manufacturer.Id }],
        Quantity: quantity,
    };
    const result = await api<PartChartData[]>("/api/part-chart", { method: "POST", body });
    try {
        if (result) {
            chartData.value = result[0];
        } else {
            chartData.value = null;
        }
    } catch (_err) {
        chartData.value = null;
    }
}

interface ChartDefinition {
    id: string;
    label: string;
    rgb: string;
    data: object;
    position?: string;
}
</script>
