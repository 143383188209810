<template>
    <div v-show="ready" class="preview-chart relative">
        <div v-if="ready" class="absolute inset-0 flex items-center justify-center">
            <Button class="bg-white" size="sm" @click="emits('click', chartData)">
                {{ $t("Charts.PreviewButtonText") }}
            </Button>
        </div>
        <div :style="previewStyle">
            <canvas ref="canvas" />
        </div>
    </div>
</template>
<script setup lang="ts">
import "chartjs-adapter-moment";
import {
    Chart,
    LineController,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Legend,
    Tooltip,
} from "chart.js";

type Props = {
    part: CommonPart;
    partKey: string;
    initialChartData?: PartChartData | null;
    previewHeight?: string | null;
    previewWidth?: string | null;
    loadChartData?: boolean;
    previewIdPrefix?: string;
};

Chart.register(LineController, TimeScale, LinearScale, PointElement, LineElement, Filler, Legend, Tooltip);

const { t } = useI18n();
const api = useApi();
const canvas = useTemplateRef("canvas");

const props = withDefaults(defineProps<Props>(), {
    initialChartData: null,
    previewHeight: null,
    previewWidth: null,
    loadChartData: false,
    previewIdPrefix: "",
});
const emits = defineEmits<{ click: [data: PartChartData | null] }>();

const chartData = ref<PartChartData | null>(props.initialChartData);
const quantity = ref<number | null>(null);
let previewChart: Chart<"line"> | null = null;

// I have data
const ready = computed(() => !!chartData.value && Array.isArray(chartData.value.Stock));

const previewStyle = computed(() => {
    return {
        width: props.previewWidth ? props.previewWidth + "px" : "",
        height: props.previewHeight ? props.previewHeight + "px" : "",
    };
});

onMounted(() => {
    if (props.loadChartData) {
        load(quantity.value);
    } else if (props.initialChartData) {
        generatePreview();
    } else {
        throw Error("You must either set loadChartData or initialChartData.");
    }
});

function generatePreview() {
    if (!canvas.value) return;
    if (!chartData.value || !chartData.value.Quantity) return;

    quantity.value = chartData.value.Quantity;

    if (previewChart) {
        previewChart.destroy();
    }

    previewChart = new Chart(canvas.value, {
        type: "line",
        data: {
            labels: chartData.value.Dates,
            datasets: [
                {
                    yAxisID: "yStock",
                    label: t("Global.Stock"),
                    data: chartData.value.Stock,
                    borderColor: "rgb(23,172,235)",
                    borderWidth: 1,
                    backgroundColor: "rgba(23,172,235, .3)",
                    fill: true,
                },
                {
                    yAxisID: "yPrice",
                    label: t("Charts.Price"),
                    data: chartData.value.Price,
                    borderColor: "rgb(34,53,64)",
                    borderWidth: 1,
                    backgroundColor: "rgba(134,153,164, .3)",
                    fill: true,
                },
            ],
        },
        options: {
            maintainAspectRatio: false,
            responsive: true,
            elements: {
                point: {
                    radius: 0,
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    type: "time",
                    ticks: {
                        display: false,
                    },
                    grid: {
                        display: false,
                        drawTicks: false,
                        drawOnChartArea: false,
                    },
                },
                yPrice: {
                    stacked: true,
                    beginAtZero: true,
                    display: false,
                    ticks: {
                        display: false,
                    },
                    grid: {
                        display: false,
                        drawTicks: false,
                        drawOnChartArea: false,
                    },
                    title: {
                        text: t("Charts.Price"),
                    },
                },
                yStock: {
                    stacked: true,
                    beginAtZero: true,
                    display: false,
                    ticks: {
                        display: false,
                    },
                    grid: {
                        display: false,
                        drawTicks: false,
                        drawOnChartArea: false,
                    },
                    title: {
                        display: false,
                    },
                },
            },
        },
    });
}

async function load(quantity: number | null) {
    // Don't make calls to the part-chart endpoint when we don't have sufficient part information
    if (!props.part.PartNumber || !props.part.Manufacturer.Id) {
        return;
    }

    const body = {
        Parts: [{ PartNumber: props.part.PartNumber, ManufacturerId: props.part.Manufacturer.Id }],
        Quantity: quantity,
    };
    const result = await api<PartChartData[] | null>("/api/part-chart", { method: "POST", body });
    try {
        if (result) {
            chartData.value = result[0];
            generatePreview();
        } else {
            chartData.value = null;
        }
    } catch (_err) {
        chartData.value = null;
    }
}
</script>
