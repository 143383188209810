<template>
    <PartStockAvailability :result="result" />
    <PartDataAge v-if="props.result.Distributor" :dist="props.result.Distributor" />
    <Popover v-if="showGlobalStock">
        <PopoverTrigger class="font-bold text-sm text-link">
            {{ $t("Search.GlobalInventoryLink") }}
        </PopoverTrigger>
        <PopoverContent>
            <table class="w-full text-center border border-gray-700">
                <thead>
                    <tr class="bg-gray-700 text-white">
                        <th class="p-1">{{ $t("Search.Region") }}</th>
                        <th class="p-1">{{ $t("Global.Stock") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in inventory" :key="index" class="border-b border-gray-700">
                        <td class="p-1">{{ item.RegionCode }}</td>
                        <td class="p-1">{{ item.Quantity }}</td>
                    </tr>
                </tbody>
            </table>
            <p class="m-1 font-light">{{ $t("Search.SeeDistributorWebsite") }}</p>
        </PopoverContent>
    </Popover>
</template>
<script setup lang="ts">
const props = defineProps<{ result: PartResult }>();

const showGlobalStock = computed(() => {
    return props.result.GlobalStock?.length > 0;
});

const inventory = computed(() => {
    if (!showGlobalStock.value) return [];
    return props.result.GlobalStock.map((item) => ({
        RegionCode: item.RegionCode,
        Quantity: item.Quantity.toLocaleString(),
    })).sort((a, b) => a.RegionCode.localeCompare(b.RegionCode));
});
</script>
