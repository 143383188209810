<template>
    <div class="text-sm font-light">
        <div v-if="inStockMessage && props.isBasic">
            {{ inStockMessage }}
        </div>
        <div v-if="props.result.QuantityOnHand && !props.isBasic" class="text-base whitespace-nowrap">
            <span class="font-bold">{{ quantityOnHandLabel }}</span>
            <span class="text-success font-semibold mx-1">{{ $t("Global.InStock") }}</span>
            <Icon name="check" />
        </div>
        <div v-for="message in stockMessages" :key="message">
            {{ message }}
        </div>
        <div v-if="factoryLeadTimeMessage">
            {{ factoryLeadTimeMessage }}
        </div>
        <Badge v-if="props.result.QuantityOnHand && props.result.IsDuplicateStock" fill="light" class="mt-1">
            *{{ $t("Search.DuplicateStock") }}
        </Badge>
        <div v-if="outOfStockMessage" class="out-of-stock-label">
            {{ outOfStockMessage }}
        </div>
    </div>
</template>

<script setup lang="ts">
const { t } = useI18n();

const FactoryLeadTimeIntervalOptions = {
    DAYS: 0,
    WEEKS: 1,
    MONTHS: 2,
};

const props = defineProps<{
    result: PartResult;
    isBasic?: boolean;
}>();

const stockMessages = computed(() => {
    const pr = props.result;
    return [
        getMessage("DropShipQuantity", pr.DropShipQuantity),
        getMessage("ValueAddQuantity", pr.ValueAddQuantity),
        getMessage("OnOrderQuantity", pr.OnOrderQuantity),
        pr.DisplayFactoryStock ? getMessage("FactoryStockQuantity", pr.FactoryStockQuantity) : "",
    ].filter((msg) => msg);
});

const quantityOnHandLabel = computed(
    () => props.result.QuantityOnHand.toLocaleString() + (props.result.IsDuplicateStock ? "*" : "")
);

const inStockMessage = computed(() => {
    let message = getMessage("QuantityInStock", props.result.QuantityOnHand);
    if (message && props.result.IsDuplicateStock) {
        message += "*";
    }

    return message;
});

const factoryLeadTimeMessage = computed(() => {
    if (
        !props.result.DisplayFactoryLeadTime ||
        !props.result.FactoryLeadTimeInterval ||
        !props.result.FactoryLeadTime
    ) {
        return "";
    }

    let interval = "";
    if (props.result.FactoryLeadTime == 1) {
        switch (props.result.FactoryLeadTimeInterval) {
            case FactoryLeadTimeIntervalOptions.DAYS:
                interval = t("Search.FactoryLeadTimeDay", [props.result.FactoryLeadTime.toLocaleString()]);
                break;
            case FactoryLeadTimeIntervalOptions.WEEKS:
                interval = t("Search.FactoryLeadTimeWeek", [props.result.FactoryLeadTime.toLocaleString()]);
                break;
            case FactoryLeadTimeIntervalOptions.MONTHS:
                interval = t("Search.FactoryLeadTimeMonth", [props.result.FactoryLeadTime.toLocaleString()]);
                break;
        }
    } else {
        switch (props.result.FactoryLeadTimeInterval) {
            case FactoryLeadTimeIntervalOptions.DAYS:
                interval = t("Search.FactoryLeadTimeDays", [props.result.FactoryLeadTime.toLocaleString()]);
                break;
            case FactoryLeadTimeIntervalOptions.WEEKS:
                interval = t("Search.FactoryLeadTimeWeeks", [props.result.FactoryLeadTime.toLocaleString()]);
                break;
            case FactoryLeadTimeIntervalOptions.MONTHS:
                interval = t("Search.FactoryLeadTimeMonths", [props.result.FactoryLeadTime.toLocaleString()]);
                break;
        }
    }

    return t("Search.FactoryLeadTime", [interval]);
});

const outOfStockMessage = computed(() => {
    if (
        !props.result.QuantityOnHand &&
        !props.result.DropShipQuantity &&
        !props.result.ValueAddQuantity &&
        !props.result.OnOrderQuantity &&
        !props.result.FactoryStockQuantity &&
        !factoryLeadTimeMessage.value
    ) {
        return t("Global.OutOfStock");
    }

    return "";
});

function getMessage(resource: string, value?: number) {
    return value ? t("Search." + resource, [value.toLocaleString()]) : "";
}
</script>
