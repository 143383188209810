<template>
    <div class="w-28 mx-auto">
        <div v-for="price in visiblePrices" :key="price.index" class="flex py-0.5">
            <template v-if="price.FormattedQuantity">
                <span>{{ price.FormattedQuantity }}</span>
                <span class="price-dots"></span>
            </template>
            <span class="whitespace-nowrap">{{ price.FormattedPrice }}</span>
        </div>
        <div v-if="showSeeMore" class="text-center">
            <Button variant="link" size="sm" @click="expandPricing">
                {{ $t("Search.SeeMore") }}
            </Button>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useWindowSize } from "@vueuse/core";

const { gtag } = useGtag();
const { width: windowWidth } = useWindowSize();

interface Price {
    FormattedQuantity: string;
    FormattedPrice: string;
}

const props = defineProps<{ prices: Price[] }>();

const showAll = ref(false);

const isLargeWindow = computed(() => (import.meta.server ? true : windowWidth.value > 992));

const breakPoint = computed(() => (isLargeWindow.value ? 5 : 2));

const visiblePrices = computed(() =>
    (showAll.value ? props.prices : props.prices.slice(0, breakPoint.value)).map((p, index) => ({ ...p, index }))
);

const showSeeMore = computed(() => props.prices.length > breakPoint.value && !showAll.value);

function expandPricing() {
    gtag("event", "results_pricing_expand");
    showAll.value = true;
}
</script>
<style>
.price-dots {
    .flex: 0 1 auto;
    overflow: hidden;
}
.price-dots::before {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    content: "....................................................................................................";
}
</style>
