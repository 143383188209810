// Part utilities

/**
 * Count how many duplicates each row has
 * Pass visibleSortOrders when the set of potential children could be further limited by search filters.
 * @returns object map where key is sort order and value is number of children
 */
export function getPartDuplicateCounts(parts: PartResult[], visibleSortOrders?: Set<string>) {
    const map = new Map<string, number>();

    parts.forEach((part) => {
        if (!part.IsDistributorDuplicate) return;
        if (visibleSortOrders !== undefined && !visibleSortOrders.has(part.SortOrder)) return;
        map.set(part.ParentSortOrder, (map.get(part.ParentSortOrder) ?? 0) + 1);
    });

    return Object.fromEntries(map.entries());
}
