export default {
    getRoHS: function (obj, $t) {
        var results = [];
        if (obj != null && obj.length > 0) {
            var result = "";
            for (var i = 0; i < obj.length; i++) {
                var comp = obj[i];
                if (comp.Region && comp.Region !== "") {
                    result += comp.Region + ": ";
                }
                if (comp.Description && comp.Description !== "") {
                    result += comp.Description;
                } else if (comp.IsCompliant === true) {
                    result += $t("Global.Yes");
                } else if (comp.IsCompliant === false) {
                    result += $t("Global.No");
                } else {
                    result += $t("Search.NotApplicable");
                }
                if (result !== "") {
                    results.push(result);
                }
            }
            return results;
        }
    },
};
